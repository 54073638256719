<template>
  <page-spinner/>
  <router-view/>
</template>

<script>
import PageSpinner from './components/utils/PageSpinner.vue'

export default {
  name: 'App',
  components: {
    PageSpinner
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.form-input {
  padding: 5px;
  margin: 5px;
  height: 30px;
  font-size: 16px;
  line-height: 30px;
  width: 70%;
}
.form-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 400px;
  padding: 15px;
  padding-top: 0;
  margin: auto;
  border: 1px solid lightblue;
  background-color: #fbfbfb;
}
.main-button {
  background-color: #258ba2;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  margin: 5px;
  width: 70%;
  cursor: pointer;
  border:none;
}

.missing-selection-message {
  color: red;
  font-size: 14px;
}
.submit-button {
    padding: 10px;
    text-align: center;
    width: 70%;
    color: white;
    background-color: #258ba2;
    cursor: pointer;
} 
.cancel-style {
  background-color: #e2eaf9;
  color: rgb(22, 75, 110);
  font-weight: 600;
  border: 1px solid #184e60;
}
.button-row {
  display: flex;
  justify-content: center;
  margin: 10px 0;
} 
.password-view-container{
    display: inline-block;
    color: #8e9b9e; 
    font-size: 16px;
    vertical-align: middle;
    margin: 0 5px;
    cursor: pointer;
}
.success-label {
  font-size: 18px;
  font-weight: 600;
  color: green;
}
.fail-label {
  font-size: 18px;
  font-weight: 600;
  color: red;
}
.load-back {
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    min-height: 400px;
}
</style>
