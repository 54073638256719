<template>
    <div>
        <div v-if="store.getters.spinner">
            <div class="load-back" :style="setHeight()"></div>
            <div class="load-bars-container">
                <img src="../../assets/images/loadBarsBlue.gif" class="load-bars" />
            </div>            
        </div>
    </div>
</template>

<script>
import store from '../../store/index'
export default {
    data() {
        return {
            store,
            pageHeight: '300px',

        }       
    },
    methods: {
        setHeight() {
            this.pageHeight = `${document.body.clientHeight}px`;
             console.log('PAGE HEIGHT', this.pageHeight)
            return {height: this.pageHeight}
           
        }
    },
    
}
</script>

<style lang="scss" scoped>

.load-bars-container {
    position: absolute;
    z-index: 10;
    top: 60%;
    width: 100%;
    text-align: center;
    
}
.load-bars {
    height: 50px;
}

</style>