<template>
    <div class="button-bar">
        <div v-if="buttonlist.length == 1" class="button-bar-button single"  @click=clickAction(0)>{{buttonlist[0] }}</div>
        <div v-if="buttonlist.length > 1">
            <div class="button-bar-button first" @click=clickAction(0)>
                {{buttonlist[0] }}
            </div>
            <div class="button-bar-button" v-for="(item,index) in buttonlist.slice(1,buttonlist.length-1)" :key="index" @click="clickAction(index + 1)" >
                {{buttonlist[index+1] }}
            </div>
            <div class="button-bar-button last" @click=clickAction(buttonlist.length-1)>
                {{buttonlist[buttonlist.length-1] }}
            </div>
        </div>
    </div>
</template>

<script>
// import EventBus from '../../utilities/EventBus'
export default {
    name: 'ButtonBar',
    data() {
        return {

        }
    },
    props: [
        'buttonlist'
    ],
    methods: {
        clickAction(index) {
            window.console.log('CLICKED ON', index)
            this.$emit('button-click',{id:index,name:this.buttonlist[index]})
        }
    },
    mounted() {
        console.log('BUTTON LIST', this.buttonlist)
        
    },
}
</script>

<style scoped>
.button-bar{
    padding: 10px;
}
.button-bar-button{
    display: inline-block;
    background-color: #298eb5;
    color: white;
    font-size: 13px;
    border-right: 1px solid white;
    padding: 6px 17px;
    font-weight: 600;
    cursor: pointer;
}
.button-bar-button:hover{
    background-color: #3f2ab7;
}
.first{
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    background-color: #236c88;
}
.last{
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #236c88;
}
.single{
    border-radius: 15px;
}

</style>