<template>
    <div>
        <img class="image-banner" src="/images/Simplify_Imaging.jpg" alt="">
    </div>
</template>

<style scoped>
.image-banner {
    width: 300px;
    margin-bottom: 15px;
    margin-top: 0;
}
</style>