import { createRouter, createWebHashHistory } from 'vue-router';
import PhotoPage from '@/Views/PhotoPage'

function loadPage(view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/Views/${view}.vue`
    );
}
const routes = [
  {
    path: '/',
    name: 'LogIn',
    component: loadPage("LogIn"),
    // meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: '/imaging',
    name: 'Imaging',
    component: PhotoPage,
    // component: loadPage("PhotoPage"),
    // meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: '/passwordreset',
    name: 'PasswordReset',
    component: loadPage("PasswordReset"),
    // meta: {
    //   requiresAuth: true,
    // }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: loadPage("RegPage"),
    // meta: {
    //   requiresAuth: true,
    // }
  },

//   {
//     path: '/sign-up',
//     name: 'SignUp',
//     component: loadPage("SignUp")
//   },
//   {
//     path: '/login',
//     name: 'LogIn',
//     component: loadPage("LogIn")
//   },
//   {
//     path: '/setup',
//     name: 'Setup',
//     component: loadPage("CreateCampaign")
//   },
//   {
//     path: '/campaigns',
//     name: 'Campaigns',
//     component: loadPage("CampaignList")
//   },
//   {
//     path: '/notext',
//     name: 'NoText',
//     component: loadPage("SetNoText")
//   },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // get current user info
  console.log('BEFORE', to, from)
//   const currentUser = supabase.auth.user();
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//   console.log('BEFORE ROUTER', requiresAuth, currentUser)
//   if(to.name==='SignUp' || to.name === 'Login') next();
//   if(requiresAuth && !currentUser) next('login');
//   // else if(!requiresAuth && currentUser) next("/");
//   else next();
next();
})

export default router