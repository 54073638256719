import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
// import router from '../router';
import axios from "axios";
// import Constants from '../utilities/Constants'
import Globals from '../utilities/Globals'; 

export default createStore({
    state: {
        userId: null,
        testers: [],
        user: {
            businessid: '',
            phone: '',
            token: 'abc',
            authToken: 'def',
        },
        confirmCode: {},
        token: '',
        authToken:'',
        isAuthenticated: false,
        showLoader: 'hello',
        spinner: true,
        selectedClient: {},
    },
    getters: {
        userId: state => state.userId,
        testers: state => state.testers,
        confirmCode: state => state.confirmCode,
        user: state => state.user,
        token: state => state.token,
        authToken: state => state.authToken,
        isAuthenticated: state => state.isAuthenticated,
        showLoader: state => state.showLoader,
        spinner: state => state.spinner,
        selectedClient: state => state.selectedClient
    },
    mutations: {
        setUserId(state, payload) {
        console.log('UDATE USRID',payload)
        state.userId = payload;
        },
        setTesters(state,payload) {
        console.log('set testers',payload)
        state.testers = payload;
        },
        setUser(state,payload) {
            console.log('UDATE USR',payload)
            state.user = payload
        },
        setToken(state,payload) {
            console.log('setTok',payload)
            state.token = payload
            state.user.token = payload
        },
        setAuthToken(state,payload) {
            console.log('AT',payload)
            state.authToken = payload
        },
        setAuth(state,payload) {
            state.isAuthenticated = payload
        },
        setShowLoader(state,payload) {
            console.log('SET LOADER', payload)
            state.spinner = payload
        },
        setSelectedClient(state,payload) {
            console.log('SET CLIENT',payload)
            state.selectedClient = payload
        },
        
    },
    actions: {
        login({commit}, data) {        
            const url = Globals.nodeURL +'photologin'
            console.log('LOGIN', url)
            return axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(function(response) {
                window.console.log('LOGIN RESPONSE RETURN', response.data,response.data.data.id);
                commit('setUser',response.data.data)
                commit('setUserId',response.data.data.id)
                commit('setToken',response.data.data.token)
                commit('setAuth',true)
                return response.data
            })
            .catch(function(error) {
                window.console.log('LOGIN ERROR', error)
            })
        },
        findClientsLastName(context, data) {
            console.log('FIND CLIENT LAST NAME', data)
            const businessid = this.state.user.businessid;
            const url = Globals.nodeURL + `clientsearch?searchterm=${data.searchterm}&id=${businessid}`
            window.console.log('SEARCH CLIENTS DATA', data);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND CLIENTS', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET CLIENT ERROR', error)
                })
        },
        findClientsFirstName(context, data) {
            console.log('FIND CLIENT FIRST NAME', data)
            const businessid = this.state.user.businessid
            const url = Globals.nodeURL + `clientsearchfirst?searchterm=${data.searchterm}&id=${businessid}`
            window.console.log('SEARCH CLIENTS DATA', data);
            return axios({
                    method: 'get',
                    url: url,
                })
                .then(function(response) {
                    window.console.log('FOUND CLIENTS', response.data);
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET CLIENT ERROR', error)
                })
        },
        setTokens() {
            console.log('SETTOK', this.state.user,this.state.userId)
            const tokens = {
                token: this.state.user.token,
                // authToken: this.state.authToken
                authToken: 'U2FsdGVkX18IhD6hemM98P7ySmOsekg4+jeg7OJVPAeJyWnwlLCmFuDoCSmprzZHlad9lnAoWILHpo/H9srI0A=='
            }
            console.log('TOKENS SET',tokens)
            axios.defaults.headers.common['Authorization'] = JSON.stringify(tokens)
        },
        getImages(context, data) {
            const vm = this
            const url = Globals.nodeURL + `images?id=${data.id}`;
            context.dispatch('setTokens')
            window.console.log('GET CLIENT IMAGES', data);
            return axios({
                    method: 'get',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('FOUND CLIENT IMAGES', response.data);
                    
                    vm.state.clientImages = response.data
                    return response.data
                })
                .catch(function(error) {
                    window.console.log('GET IMAGES ERROR', error)
                })
        },
        saveImaging(context, data) {
            const url = Globals.nodeURL + 'images'
            window.console.log('store IMAGE', url, 'IMAGE INFO', data)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('STORE IMAGE', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    alert(error)
                    window.console.log(error);
                });
        },
        getImageNote(context,refnum) {
            console.log('GET IMAGE NOTE', refnum)
            const data = {'refnum': refnum}
            const url = `${Globals.nodeURL}imagenote?id=${refnum}`;
            return axios({
                method: 'get',
                url:url,
                data: data
            })
            .then(function(response) {
                window.console.log('GOT NOTE', response.data.data[0]);
                return response.data.data[0];
            })
            .catch(function(error) {
                alert(error)
                window.console.log(error);
            });
        },
        updateImageNote(context,data) {
            console.log('UPDATE IMAGE NOTE', data)
            const url = `${Globals.nodeURL}imagenote`;
            return axios({
                method: 'put',
                url:url,
                data: data
            })
            .then(function(response) {
                window.console.log('GOT NOTE UPDATE RESPONSE', response);
                return response.data.result;
            })
            .catch(function(error) {
                alert(error)
                window.console.log(error);
            });
        },
        uploadImage(context, image) {
            const url = Globals.nodeURL + 'imageuploads'
            //context
            let formData = new FormData()
            formData.append('file',image)
            const data={'image': image,'name':image.name}
            window.console.log('upload IMAGE', url, 'IMAGE UPLOAD DATA', image.name)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UPLOADED IMAGE', response.data);
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        deleteImage(context, data) {
            const url = Globals.nodeURL + 'imagesdelete'
            window.console.log('store DELETE IMAGE', url, context, 'DATA', data)
            return axios({
                    method: 'put',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('UP DELETE IMAGE', response.data);
                    
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        forgotPassword(context,data) {
            const url =  Globals.nodeURL + 'staffconfirm';
            data.refnum = Math.round(Math.random() * 1000000).toString();
            const date = new Date()
            this.state.confirmCode = {time: date.getTime(),code:data.refnum}
            const vm = this
            window.console.log('REF', data, url)
            return axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(function(response) {
                    window.console.log('RESPONSE FORGOT PASSWORD', response.data, vm.state.user);
                    vm.state.user.phone = response.data.data
                    return response.data;
                })
                .catch(function(error) {
                    window.console.log(error);
                });
        },
        changePassword(context, data) {
            const url =  Globals.nodeURL + 'chgpass';
            return axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(function(response) {
                window.console.log('RESPONSE CHANGE PASSWORD', response.data);
                return response.data;
            })
            .catch(function(error) {
                window.console.log(error);
            });
        },
        changeNewPassword(context, data) {
            const vm = this
            const url =  Globals.nodeURL + 'chgpassNew';
            return axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(function(response) {
                window.console.log('RESPONSE CHANGE NEW BUSINESS PASSWORD', response.data);
                vm.state.user.password = data.newpassword
                return response.data;
            })
            .catch(function(error) {
                window.console.log(error);
            });
        },
        sendNewPassword(context,data) {
            const url =  Globals.nodeURL + 'sendPassword';
            return axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(function(response) {
                window.console.log('RESPONSE SEND PASSWORD', response.data);
                return response.data;
            })
            .catch(function(error) {
                window.console.log(error);
            });
        },
        resendPassword(context,data) {
            window.console.log('RESEND EMAIL', data)
            const url =  Globals.nodeURL + 'resendPassword';
            return axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(function(response) {
                window.console.log('RESPONSE RESEND PASSWORD', response.data);
                return response.data;
            })
            .catch(function(error) {
                window.console.log(error);
            });
        },
        regNewUser(context,data) {
            const url = Globals.nodeURL + 'regimageuser';
            return axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(response => {
                console.log('REG NEW', response)
                return response
            })
            .catch(error => {
                console.log('ERROR REG NEW', error)
            })
        },
        getBusinesses() {
            const url =  Globals.nodeURL + 'getbiz';
            return axios({
                method: 'get',
                url: url
            })
            .then(response => {
                console.log('BIZ', response)
                return response.data
            })
            .catch(error => {
                console.log('ERROR GETTING BIZ', error)
            })
        },
        addImagingClient(context,data) {
            const url =  Globals.nodeURL + 'addimageclient';
            console.log('add client imaging', data)
            return axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(response => {
                console.log('ADD CLIENT RES', response)
                return response.data
            })
            .catch(error => {
                console.log('ERROR ADD CLIENT', error)
            })
        }
        
    },
    plugins: [createPersistedState()],
    modules: {
    }
})