<template>
    <div id="photo-page">
        <Header />
        <AddClient 
            v-if="showAddClient"
            v-on:close-add-client="showAddClient=false,showImageEdit=false"
            class="add-client-component"
        />
        <div>
            <h2>{{store.getters.user.name}} Imaging</h2>
            <SearchAhead 
                v-bind:searchtype="clientSearch.type" 
                v-bind:searchcall="clientSearch.dbcall" 
                v-bind:resultfields="clientSearch.resultfields"
                v-on:item-selected="selectedClient = $event" 
                :searchTerm="selectedClientName"
                :clearterm="true" 
                @input="showImageEdit=false">
            </SearchAhead>
            <SelectionSwitch  
                :switches="['FIRST NAME','LAST NAME']"
                v-on:item-selected="selectedSearchType = $event"
                :selectedSwitch= "'LAST NAME'">
            </SelectionSwitch>
            <div class="add-button" @click="showAddClient=true">+</div>
        </div>
        <div>
            <div class="client-name">{{store.getters.selectedClient.firstname}} {{store.getters.selectedClient.lastname}}</div>
        </div>
        <div class="booking-image-edit-container" id="image-editing-container" v-if="showImageEdit">
            <ImagePicture
                :bookinginfo="bookingInfo"
                :imageinfo="imageInfo"
                :takepicture="takePicture"
                v-on:close-edit="showImageEdit = $event,showCamera = $event"
            />
        </div>
        <div class="imaging-version" >version {{ versionNumber }}</div>
        
    </div>
</template>

<script>
import SearchAhead from '../components/utils/SearchAhead';
import ImagePicture from '../components/utils/ImagePicture';
import store from '../store/index';
import Header from '../components/PageHeader.vue';
import SelectionSwitch from '../components/utils/SelectionSwitch.vue';
import AddClient from '../components/AddClient.vue';
import Version from '../utilities/version.json'

export default {
    name: 'PhotoPage',
    components: {
        SearchAhead,
        ImagePicture,
        Header,
        SelectionSwitch,
        AddClient
    },
    data() {
        return {
            clientInfo: {},
            selectedClient: {},
            clientRef: {},
            selectedClientName: '',
            selectedSearchType: 'LAST',
            imageInfo:{},
            showImageEdit: false,
            showAddClient: false,
            takePicture: false,
            store,
            clientSearch: { 
                dbcall: 'findClientsLastName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            firstNameSearch:  {
                dbcall: 'findClientsFirstName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            lastNameSearch:  {
                dbcall: 'findClientsLastName',
                type: 'Client',
                resultfields: ['firstname','lastname']
            },
            versionNumber: Version.version,
        }
    },
    methods: {
        getClientInfo() {
            if (this.bookingInfo.id) {
                this.clientRef = {
                    clientid: this.bookingInfo.clientid,
                    bookingid: this.bookingInfo.id,
                    clientname: this.bookingInfo.client.split(' ')[1]
                }
                this.$store.dispatch('getClientId',{id: this.bookingInfo.clientid}).then(response => {
                    this.clientInfo = response[0]
                    this.clientRef.refname = this.clientInfo.refname
                })
                window.console.log('CLIENT INFO', this.clientRef)
                this.clientContactInfo.email = this.bookingInfo.clientemail
                this.clientContactInfo.phone = this.bookingInfo.clientphone
                this.showClientInfoPanel = true
            } else {
                this.$router.push('Schedule')
            }
        },
    },
    watch: {
        selectedClient() {
            console.log('SELINFO', this.selectedClient, this.selectedClient.id)
            this.clientInfo = this.selectedClient
            this.store.commit('setSelectedClient',this.selectedClient)
            this.showClientContact = true
            this.clientRef = {
                clientid: this.selectedClient.id,
                refname: this.clientInfo.refname,
                bookingid: 0,
                hideCameraButton: true
            }
            window.console.log("SELECTED______",this.selectedClient,this.clientRef)
            window.console.log('CLIENT INFO', this.clientInfo)
            this.showImageEdit = true;
            // this.store.dispatch('getImages',{id: this.selectedClient.id}).then(response => {
            //     console.log('GOT IMAGES', response)
            //     this.showImageEdit = true;
            // })
            
        },
        selectedSearchType() {
            window.console.log('TYPE',this.selectedSearchType)
            if (this.selectedSearchType == 'FIRST NAME') {
                this.clientSearch = this.firstNameSearch
            }
            if (this.selectedSearchType == 'LAST NAME') {
                this.clientSearch = this.lastNameSearch
            }
        },
    },
    mounted() {
        this.store.commit('setShowLoader', false)
        this.selectedClient=this.store.getters.selectedClient
    }
}
</script>

<style scoped>
h2 {
    margin-top: 0;
}
.client-name {
    font-size: 20px;
    font-weight: 600;
    padding: 15px;
    color: #6d0dd1;
}
.add-button {
    width: 30px;
    height: 30px;
    background-color: #459699;;
    color: white;
    font-size: 20px;
    font-weight: 700;
    border-radius: 15px;
    display: inline-block;
    line-height: 30px;
    margin: 7px 15px;
    position: absolute;
    cursor: pointer;
}
.add-client-component {
    position: absolute;
    width: 98.5%;
    top: 0;
}
.imaging-version {
    font-size: 11px;
    position: relative;
    bottom: 5px;
    text-align: center;
    width: 100%;
    padding: 5px;
}
</style>