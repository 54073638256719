<template>
    <div class='selection-switch-container'>
        <div 
            class="switch-button" 
            v-for="(item,index) in switches"  
            v-bind:key="index"
            :class="{'active': isActive(item)}"
            @click="selectItem(item)">
            {{item}}
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectionSwitch',
    data() {
        return {
            activeItem: '',
        }
    },
    props: [
        'switches', 'selectedSwitch'
    ],
    methods: {
        isActive(item) {
            return item === this.activeItem ? true : false
        },
        selectItem(item) {
            this.$emit('item-selected', item.toUpperCase())
            window.console.log('EMIT', item)
            this.activeItem = item

        }
    },
    computed: {
        getList() {
            return this.itemlist
        }
    },
    mounted() {
        this.activeItem = this.selectedSwitch
    },
    watch: {
        selectedSwitch() {
            this.activeItem = this.selectedSwitch
        }
    }
    
}
</script>

<style scoped>
.selection-switch-container{
    display: inline-block;
    border: 1px solid #6398bd;
    margin-left: 0;
    
}
.switch-button{   
    font-size: 10px;
    padding: 8px;
    display: inline-block;
    border-right: 1px solid #6398bd;
    cursor: pointer;
    line-height: 24px;
    background-color: #e8edef ;
    color: #9a9a9a;   
}
.switch-button:last-child{
    border-right: none;
}
.active {
    background-color: #506265;
    color: white;
    font-weight: 600;
    height: 24.6px;
    vertical-align: top;
    margin-top: -1px;
    margin-left: -1px;
    border: 1px solid black;
}
</style>