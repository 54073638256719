<template>
    <div>
        <div class="load-back" :style="getHeight()"></div>
        <div class="form-container front">
            <div class="form-content" @input="clearErrors">
                <h2>{{'Add Client'}}</h2>
            
                <div class="form-input-row">
                    <input class="form-input med-width" type="text" v-model="user.firstname" placeholder="First Name"/>
                </div>
                <div class='missing-selection-message' 
                    v-if="showError && !user.firstname ">
                    *{{'You need to enter a'}} {{'First Name'}}
                </div>

                <div class="form-input-row">
                    <input class="form-input med-width" type="text" v-model="user.lastname" placeholder="Last Name"/>
                </div>
                <div class='missing-selection-message' 
                    v-if="showError && !user.lastname ">
                    *{{'You need to enter a'}} {{'Last Name'}}
                </div>

                <div class="form-input-row">
                    <input class="form-input med-width" type="email" v-model="user.email" placeholder="Email Address"/>
                </div>
                <div class='missing-selection-message' 
                    v-if="showError && !user.email ">
                    *{{'You need to enter an Email Address'}}
                </div>
                <div class='missing-selection-message' 
                    v-if="showError && !validEmail ">
                    *{{'You need to enter a valid Email Address'}}
                </div>

                <div class="form-input-row">
                    <input 
                        class="form-input med-width" 
                        type="phone" v-model="user.phone" 
                        placeholder="Phone" 
                        @input="formatPhoneNumber"/>
                </div>
                <div class='missing-selection-message' 
                    v-if="showError && !user.phone ">
                    *{{'You need to enter a Phone Number'}}
                </div>
                <div class='missing-selection-message' 
                    v-if="showError && !validPhone ">
                    *{{'You need to enter a valid Phone Number'}}
                </div>
                </div>
                <div class="button-row">
                    <div class="submit-button" @click="verifyEntry">{{'Submit'}}</div>
                </div>
                <div class="button-row single">
                    <div class="submit-button cancel-style" @click="hideEntry">Cancel</div>
                </div>
                <div class="form-input-row" v-show="showSuccess">
                    <div class="success-label">{{'New Client Successfully Added'}}</div>
                </div> 
                <div class="form-input-row" v-show="showExists">
                    <div class="fail-label">{{'Client Email Already Exists'}}</div>
                </div> 
                <div class="form-input-row" v-show="showFail">
                    <div class="fail-label">An error occurred. Please try again. If problem persists contact Administrator</div>
                </div>
            </div>
        </div>
</template>

<script>
import Globals from '../utilities/Globals'
import store from '../store/index'
export default {
    components: {
    },
    data() {
        return {
            store,
            user: {
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                businessid: ''
            },
            showError: false,
            validEmail: false,
            validPhone: false,
            showSuccess: false,
            showExists: false,
            showFail: false,
            foundName: '',
            setHeight: Globals.setHeight()
        }
    },
    methods: {
        getHeight() {
            return Globals.setHeight()
        },
        hideEntry() {
            console.log('hide entry')
            this.$emit('close-add-client')
        },
        verifyEntry() {
            let isError = false;
            this.clearErrors();
            if(!this.user.firstname || !this.user.lastname || !this.user.email || !this.user.phone) {
                isError = true;
                this.showError = true;
            }
            if (!Globals.validateEmail(this.user.email)) {
                isError = true;
                this.showError = true;
                this.validEmail = false;
            }
            if (this.user.phone.length !== 12) {
                isError = true;
                this.showError = true;
                this.validPhone = false;
            }
            if (!isError) {
                this.submitReg()
            }
        },
        clearErrors() {            
            this.validEmail = true;
            this.validPhone = true;
            this.showError = false;
            this.showSuccess = false;
            this.showExists = false;
            this.showFail = false;
            this.foundName = '';
        },
        submitReg () {           
            let data = {...this.user};
            data.phone = data.phone.replaceAll('-','')
            data.businessid = this.store.getters.user.businessid
            console.log('SUBMIT REG CLIENT', this.user, data)
            this.store.commit('setShowLoader',true)
            this.store.dispatch('addImagingClient',data)
            .then( response => {
                console.log('NEW Client REG' , response)
                this.store.commit('setShowLoader',false)
                if (response.result === 'SUCCESS') {
                    this.showSuccess = true;
                    data.id = response.data                   
                    setTimeout(() => {
                        this.showSuccess = false;
                        this.store.commit('setSelectedClient',data)
                        console.log('SET SELECTED', data)
                        this.hideEntry();
                    }, 3000)
                }
                if (response.result === 'EXISTS') {
                    // this.foundName = `${response.data.firstname} ${response.data.lastname}`
                    this.showExists = true;
                }
                if (response.result === 'FAIL') {
                    this.showFail = true;
                }
            })
            .catch(error => {
                this.store.commit('setShowLoader',false)
                console.log('ERROR OCCURRED', error)
            })
        }, 
        formatPhoneNumber() {
            let cleaned = ('' + this.user.phone).replace(/\D/g, '')
            this.user.phone = Globals.formatPhone(cleaned)
        },
        
    },
    mounted() {
        
    },
    watch: {
        
    }

}
</script>

<style lang="scss" scoped>
.single {
    margin-top: 5px;
}
.front {
    z-index: 6;
    position: relative;
    top: 80px;
}
</style>