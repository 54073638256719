<template>
    
        <div class="search-ahead-container" >          
            <div class="search-row">                              
                <input class="input-search" :placeholder="searchPlaceholder" v-model="searchText" v-on:blur="sendTerm" />
                <div class="results-container" v-if="showResults">
                    <div 
                        class="result-item" 
                        v-for="item in searchResults" 
                        v-bind:key="item.id"
                        @click="itemSelected(item)"
                        v-on:click="$emit('item-selected', item)">
                        {{getResultItem(item)}}
                    </div>
                </div>        
            </div>                      
        </div>       
</template>

<script>
import store from '../../store/index';
export default {
    name: 'SearchAhead',
    data: function() {
        return {
            searchResults: [],
            searches: {},
            store,
            isSelected: false,
            showResults: false,
            initialSet: false,
            searchText: ''
        }
    },
    props: [
        'searchtype',
        'resultfields',
        'searchcall',
        'searchTerm',
        'clearterm',
    ],
    methods: {
        searchClient(){
                this.store.dispatch(this.searchcall,{searchterm: this.searchText}).then(response => {
                    this.searchResults = response;
                    if (this.searchResults.length>0){
                        this.showResults = true
                    }
                })            
        },
        itemSelected(item) {
            console.log('ITEM SELECTED', item.id)
            this.selectedItem = item
            this.isSelected = true;
            if (this.clearterm) {
                this.searchText= ''
            } else {
                this.searchText= this.getResultItem(item)
            }
        },
        getResultItem(item) {
            let resultDisplay = ''
            this.resultfields.forEach(field => {
                resultDisplay += item[field] + ' '
            })
            return resultDisplay
        },
        sendTerm() {
            this.$emit('new-term', this.searchText)
        }
        
    },
    computed: {
        startSearch() {
            this.searchClient()
            return true
        },
        searchPlaceholder: {
            get: function(){
                return 'Search for ' + this.searchtype;
            }
        }
        
    },
    mounted() {
        this.searchText = this.searchTerm
        setTimeout(() => {
            this.showResults = false
        }, 100);
        
    },
    watch: {
        searchText: function() {
            if (!this.initialSet) {
                if (this.isSelected){
                    this.searchResults = []
                    this.isSelected = false
                    this.showResults = false;
                } else {
                    window.console.log('NOT SELCTED')
                    if(this.searchText){
                        this.searchClient()
                    } else {
                        this.searchResults = []
                    }                
                }
            } 
            this.initialSet = false          
        },
        searchTerm(){
            this.initialSet = true
            this.searchText = this.searchTerm
        }
    }
}
</script>

<style scoped>
.search-ahead-container{
    position: relative;
    display: inline-block;
    margin-left: 5px;
    z-index: 3;
    height: 30px;
    vertical-align: top;
}
.input-search {
    height: 22px;
    background-color: white;
    border: 1px solid rgb(151, 180, 213);
    width: 150px;
    margin: 4px 0 20px 0;
    /* padding: 3px 10px 3px 5px; */
    font-size: 16px;
    padding: 10px;
}
.results-container{
    position: absolute;
    background-color: white;
    border: 1px solid gray;
    left: 0;
    top: 42px;
    min-width: 165px;
    width: max-content;
}
.result-item{
    border-bottom: 1px solid lightgray;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
}
.result-item:hover{
    background-color: lightgray;
}
.icon-symbol {
    display: inline-block;
    padding-top: 3px;
    vertical-align: middle;
}
.search-row{
    margin-top: -4px;
    display: inline-block;
    height: 30px;
}
.search-input{

}
</style>