import { createApp } from 'vue'
// import App from './App.vue'

// createApp(App).mount('#app')

// import Vue from 'vue'
import App from './App.vue'
// import VueRouter from 'vue-router'
import router from './router'
import { store } from './store/index'

// Vue.config.productionTip = false;
// const router = new VueRouter({
//   mode: 'hash', 
//   base: process.env.BASE_URL,routes
// })

// Vue.use(VueRouter);

createApp(App).use(store).use(router).mount('#app')

// new Vue({
//   router,
//   store,
//   render: h => h(App),
// }).$mount('#app')

